import authHeader from "./auth-header";
import { handleResponse } from './handle-response';
import http from "./http-common";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from 'uuid';

const API_URL = "https://api.nuban.ar/api/v1/";
//const API_URL = "http://localhost:8000/api/v1/";

const user = JSON.parse(localStorage.getItem('user'));

const upload = (file, document, onUploadProgress) => {
  let data = new FormData();
  data.append("images", file);
  data.append('email', user.email);
  data.append('document', document);
  data.append('id', uuidv4());

  // Agrega el encabezado Content-Type necesario para archivos
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authHeader(), // Asegúrate de que authHeader() también esté configurando correctamente el encabezado de autorización
    },
    onUploadProgress,
  };

  return http.post("/uploadfiles_nuban", data, config);
};

/**
 * obtiene descuento del cliente
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
const getDescuento = (id) => {
  const data = new FormData();
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_descuento_nuban', requestOptions).then(handleResponse));
};

/**
 * agrega modifica descueto por cliente
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
const addmodDescuento = (id, row) => {
  const data = new FormData();
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_descuento_nuban', requestOptions).then(handleResponse));
};


const addNota = (nota) => {
  const data = new FormData();
  data.append('nota', nota);
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'add_nota_nuban', requestOptions).then(handleResponse));
};

/**
 * get textos 
 * @param {*} id 
 * @returns 
 */
const getTextos = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_textos_nuban', requestOptions).then(handleResponse));
};

/**
 * get texto 
 * @param {*} id 
 * @returns 
 */
 const getTexto = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_texto_nuban', requestOptions).then(handleResponse));
};

/**
 * agrega modifica texto
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
const addmodTexto = (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_texto_nuban', requestOptions).then(handleResponse));
};

/**
 * elimina un texto 
 * @param {*} id 
 * @returns 
 */
const delTexto = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_texto', requestOptions).then(handleResponse));
};

/**
 * get direcciones 
 * @returns 
 */
const getDirecciones = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_direcciones_nuban', requestOptions).then(handleResponse));
};

/**
 * get direccionid 
 * @returns 
 */
 const getDireccionid = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_direccionid_nuban', requestOptions).then(handleResponse));
};

/**
 * elimina una direccion 
 * @param {*} id 
 * @returns 
 */
const delDireccion = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_direccion', requestOptions).then(handleResponse));
};

/**
 * agrega o modifica una direccion 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const addmodDireccion = (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_direccion', requestOptions).then(handleResponse));
};

//get categorias
const getCategorias = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_categorias_nuban', requestOptions).then(handleResponse));
};

//get categorias nombre
const getCategoriasNombre = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_categorias_nuban_nombre', requestOptions).then(handleResponse));
};

//get subcategorias
const getSubcategorias = (child_id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('child_id', child_id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_sub_categorias_nuban', requestOptions).then(handleResponse));
};

//get historial pedidos
const getHistorialPedidos = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  //return (fetch(API_URL + 'get_articulos_nuban', requestOptions).then(handleResponse));
  const res = await fetch(API_URL + 'get_historial_pedidos_nuban', requestOptions).then(handleResponse);
  return res;
};

//get recupear pedidos
const getRecuperarPedidos = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  //return (fetch(API_URL + 'get_articulos_nuban', requestOptions).then(handleResponse));
  const res = await fetch(API_URL + 'get_recuperar_pedidos_nuban', requestOptions).then(handleResponse);
  return res;
};

//get articulos
const getArticulos = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  //return (fetch(API_URL + 'get_articulos_nuban', requestOptions).then(handleResponse));
  const res = await fetch(API_URL + 'get_articulos_nuban', requestOptions).then(handleResponse);
  return res;
};

//get articulos list
const getArticulosList = async (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  //return (fetch(API_URL + 'get_articulos_nuban', requestOptions).then(handleResponse));
  const res = await fetch(API_URL + 'get_articulos_nuban_list', requestOptions).then(handleResponse);
  return res;
};

//get searh articulos
const getSearchArticulos =  (id, tipo) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('tipo', tipo);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_articulos_nuban1', requestOptions).then(handleResponse));
  //const res = await fetch(API_URL + 'get_articulos_nuban1', requestOptions).then(handleResponse);
  //return res;
};

//update articulos
const updateArticulos = async (email, producto) => {
  const data = new FormData();
  data.append('email', email);
  data.append('producto', producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  //return (fetch(API_URL + 'update_articulos_nuban', requestOptions).then(handleResponse));
  const res = fetch(API_URL + 'update_articulos_nuban', requestOptions).then(handleResponse);
  return res;
};

//get clientes
const getClientes = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_clientes_nuban', requestOptions).then(handleResponse));
};

/**
 * Update clientes
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
const updateClientes = async (email, producto) => {
  const data = new FormData();
  data.append('email', email);
  data.append('producto', producto);

  const requestOptions = { 
    method: 'POST',
    body: data,
    headers: authHeader(),
    timeout: 1200000 
  };
  return await fetch(API_URL + 'update_clientes_nuban', requestOptions).then(handleResponse);
};

const updateClientes1 = async (email, producto) => {
  try {
    const response = await fetch('https://api.example.com/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        // Datos a enviar
      }),
      timeout: 5000 // Tiempo de espera en milisegundos
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }
    
    const data = await response.json(); // Convertir la respuesta en un objeto JSON
    console.log(data); // Manejar la respuesta
  } catch (error) {
    console.error(error); // Manejar el error
  }
};


//validar emails
const validEmails = (email, producto) => {
  const data = new FormData();
  data.append('email', email);
  data.append('producto', producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'validEmail', requestOptions).then(handleResponse));
};

//validar empresa
const validEmpresa = (empresa, producto) => {
  const data = new FormData();
  data.append('empresa', empresa);
  data.append('producto', producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'validEmpresa', requestOptions).then(handleResponse));
};

//trae estado del vida
const getlive = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getlive', requestOptions).then(handleResponse));
};

//trae datos pora el informe
const getInforme = (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getinforme', requestOptions).then(handleResponse));
};

//fin de compra
const endPedido = (row, orden_compra, customer_note , bonificacion, iva, percepcion, ttemp, tsiva, bbas, bgeneral, bcliente, iddireccion ) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));
  data.append('orden_compra', orden_compra);
  data.append('customer_note', customer_note);
  data.append('bonificacion', bonificacion);
  data.append('iva', iva);
  data.append('percepcion', percepcion);
  data.append('ttemp', ttemp);
  data.append('tsiva', tsiva);
  data.append('bbas', bbas);
  data.append('bgeneral', bgeneral);
  data.append('bcliente', bcliente);
  data.append('iddireccion', iddireccion);
  

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'end_pedido', requestOptions).then(handleResponse));
};

//fin de compra sin confirmar
const endPedidoSinConfirmar = (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));
  data.append('orden_compra', '');
  data.append('customer_note', '');

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'end_pedido_sin_confirmar', requestOptions).then(handleResponse));
};

//trae lista de estados por id de equipo
const getEstadosId = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getestadosid', requestOptions).then(handleResponse));
};

//trae lista de estados
const getEstados = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getestados', requestOptions).then(handleResponse));
};

//elimina un estado
const delEstado = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delestado', requestOptions).then(handleResponse));
};

//trae lista de pedidos
const getPedidos = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getpedidos', requestOptions).then(handleResponse));
};

//trae datos de un pedidos
const getPedido = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getpedido', requestOptions).then(handleResponse));
};

//trae datos de un pedidos
const getPedidoHistorial = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_pedido_historial_nuban', requestOptions).then(handleResponse));
};

//trae datos de un pedidos
const getPedidoRecuperar = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_pedido_recuperar_nuban', requestOptions).then(handleResponse));
};

//agreda o modifica un pedido
const addmodPedido = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodpedido', requestOptions).then(handleResponse));
};

//elimina un pedido
const delPedido = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delpedido', requestOptions).then(handleResponse));
};

//trae lista de usuarios
const getUsuarios = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getusuarios', requestOptions).then(handleResponse));
};

//trae datos de un usuario
const getUsuario = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getusuario', requestOptions).then(handleResponse));
};

//agreda o modifica un usuario
const addmodUsuario = (id, row, actividades) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));
  data.append('act', JSON.stringify(actividades));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmodusuario', requestOptions).then(handleResponse));
};

//elimina un usuario
const delUsuario = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delusuario', requestOptions).then(handleResponse));
};

//trae lista de los archivos adjuntos
const getFiles = (id, documento) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('document', documento);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'getfiles_nuban', requestOptions).then(handleResponse));
};


//elimina un archivo de un equipo
const delImage = (id, key) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('key', key);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'delimage_nuban', requestOptions).then(handleResponse));
};

//trae las bonificaciones del cliente
const getBonificaciones = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_bonificaciones', requestOptions).then(handleResponse));
};

//trae el minimo
const getMinimo = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_minimo', requestOptions).then(handleResponse));
};

//trae el estado
const getOfflineOnline = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_offline_online', requestOptions).then(handleResponse));
};

//cambia estado 
const addmodOfflineOnline = (estado) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('estado', estado);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'put_offline_online', requestOptions).then(handleResponse));
};

/**
 * agrega o modifica el minimo 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
 const addmodMinimo = (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_minimo', requestOptions).then(handleResponse));
};

//trae el minimo
const getMinimoid = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_minimo_id', requestOptions).then(handleResponse));
};

/**
 * agrega o modifica adecuacion 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
 const addmodAdecuacion = (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_adecuacion', requestOptions).then(handleResponse));
};

//trae adecuacion
const getAdecuacion = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_adecuacion', requestOptions).then(handleResponse));
};

//trae adecuacion
const getAdecuacionid = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_adecuacion_id', requestOptions).then(handleResponse));
};

/**
 * Deuda
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
const getDeuda = () => {
  const data = new FormData();
  data.append('id', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_deuda_nuban', requestOptions).then(handleResponse));
};

//trae lista de novedades
const getNovedades = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_novedades_nuban', requestOptions).then(handleResponse));
};

//trae lista de novedades activas
const getNovedadesActivas = () => {
  const data = new FormData();
  //data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_novedades_activas_nuban', requestOptions).then(handleResponse));
};

//trae datos de la novedad
const getNovedad = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_novedad_nuban_id', requestOptions).then(handleResponse));
};

/**
 * agrega modifica novedad
 * @param {*} email 
 * @param {*} producto 
 * @returns 
 */
const addmodNovedad = (id, row) => {
  const data = new FormData();
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_novedad_nuban', requestOptions).then(handleResponse));
};

/**
 * elimina una novedad 
 * @param {*} id 
 * @returns 
 */
const delNovedad = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_novedad_nuban', requestOptions).then(handleResponse));
};

/**
 * elimina una novedad leida
 * @param {*} id 
 * @returns 
 */
const readNovedad = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'read_novedad_nuban', requestOptions).then(handleResponse));
};

//trae datos de los administradores
const getAdministradores = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_administradores_nuban', requestOptions).then(handleResponse));
};

const addmodAdministradores = (_username, _email, _password, _empresa, _producto) => {
  const data = new FormData();
  data.append('_username', _username);
  data.append('_email', _email);
  data.append('_password', _password);
  data.append('_empresa', _empresa);
  data.append('_producto', _producto);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_administradores_nuban', requestOptions).then(handleResponse));
};

//trae listaod de archivos
const getListadoDeArchivos  = async () => {
  const data = new FormData();
  data.append('email', user.email);

  var details = {
    '_username': 'rrhh@industriabasica.com.ar',
    '_password': 'elrrhh'
  };

  var API_URL='https://rh.industriabasica.net/api/';
  var API_URL2='https://rh.industriabasica.net/api/v1/';

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  formBody = formBody.join("&");

  return axios
    .post(API_URL + "login_check", formBody,  {headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}} )
    .then(response => {
      if (response.data.token) {
        //var dat = jwt_decode(response.data.token);
        //dat.accessToken = response.data.token;
        //data.username = data.email;
        //localStorage.setItem("user", JSON.stringify(data));
      }        
      
      var token = response.data.token;

      const requestOptions = {
        method: 'POST',
        body: data,
        headers: {
          Authorization: 'Bearer ' + token
        }
      }; 

      //const requestOptions = { method: 'POST', body: data, headers: authHeader() };
      return (fetch(API_URL2 + 'archivos', requestOptions).then(handleResponse));
    
    });   
};

const getPdf = async (archivo) => {
  //const data = new FormData();
  //const data = new FormData();
  //data.append('email', user.email);
  //data.append('archivo', archivo);

  var details = {
    '_username': 'rrhh@industriabasica.com.ar',
    '_password': 'elrrhh'
  };

  var API_URL = 'https://rh.industriabasica.net/api/';
  var API_URL2 = 'https://rh.industriabasica.net/api/v1/';

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  formBody = formBody.join("&");

  try {
    const loginResponse = await axios.post(API_URL + "login_check", formBody, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
    });

    if (loginResponse.data.token) {
      //var data = jwt_decode(loginResponse.data.token);
      //data.accessToken = loginResponse.data.token;
      //data.username = data.email;
    }

    var token = loginResponse.data.token;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    //data.append('email', user.email);


    const archivosResponse = await fetch(API_URL2 + `archivo_pdf?email=${encodeURIComponent(user.email)}&archivo=${encodeURIComponent(archivo)}`, requestOptions);

    //const archivosResponse = await fetch(API_URL2 + 'archivo_pdf', requestOptions);
    if (!archivosResponse.ok) {
      throw new Error('Error al obtener el archivo PDF');
    }

    const blob = await archivosResponse.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'archivo.pdf');
    document.body.appendChild(link);
    link.click();

  } catch (error) {
    console.error('Error al obtener el archivo PDF', error);
  }
};

const checkFile = async (url) => {
  const data = new FormData();
  data.append('url', url);
  
  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'check_file_exists', requestOptions).then(handleResponse));
};

//trae datos de los administradores
const getDuplicados = () => {
  const data = new FormData();
  data.append('email', user.email);
  //data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'check_user_duplicado', requestOptions).then(handleResponse));
};

//nombre user
const getNombre = () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_nombre_nuban', requestOptions).then(handleResponse));
};

export default {

  getNombre,
  getDuplicados,
  checkFile,
  getPdf,
  getListadoDeArchivos,  
  addmodAdministradores,
  getAdministradores,

  addmodDescuento,
  getDescuento,

  addmodTexto,
  getTexto,
  getTextos,
  delTexto,

  getDeuda,

  getDireccionid,
  getDirecciones,
  addmodDireccion,
  delDireccion,
  getCategorias,
  getCategoriasNombre,
  getSubcategorias,

  getHistorialPedidos,
  getRecuperarPedidos,
  updateArticulos,
  getArticulos,
  getArticulosList,
  getSearchArticulos,
  updateClientes,
  getClientes,

  validEmpresa,
  validEmails,

  getlive,

  getInforme,

  getEstadosId,
  getEstados,
  delEstado,

  getPedidos,
  getPedido,
  getPedidoHistorial,
  getPedidoRecuperar,
  addmodPedido,
  delPedido,
  endPedido,
  endPedidoSinConfirmar,

  getMinimo,
  getMinimoid,
  addmodMinimo,

  getAdecuacion,
  getAdecuacionid,
  addmodAdecuacion,

  getUsuarios,
  getUsuario,
  addmodUsuario,
  delUsuario,

  getNovedadesActivas,
  getNovedades,
  getNovedad,
  addmodNovedad,
  delNovedad,
  readNovedad,

  upload,
  getFiles,
  
  delImage,
  getBonificaciones,
  addmodOfflineOnline,
  getOfflineOnline,
  addNota,
};
